import "./Footer.css";
import { FaInstagram, FaYoutube, FaMedium, FaSpotify } from "react-icons/fa";
// import "@fortawesome/fontawesome-free/css/all.min.css";

const Footer = () => {
  return (
    <footer className="mt-5">
      <div className="footer-content ">
        <h3
          style={{
            backgroundColor: "transparent",
            color: "hsl(219, 48%, 8%)",
            fontWeight: "bold",
          }}
        >
          Pandu Windito
        </h3>
        <div className="row mt-3" style={{ backgroundColor: "transparent" }}>
          <div className="col" style={{ backgroundColor: "transparent" }}>
            <a
              className="social"
              href="https://www.instagram.com/pandu_windito/"
              target="_blank"
            >
              <FaInstagram />
            </a>
          </div>
          <div className="col" style={{ backgroundColor: "transparent" }}>
            <a
              className="social"
              href="https://www.youtube.com/pandu_windito"
              target="_blank"
            >
              <FaYoutube />
            </a>
          </div>
          <div className="col" style={{ backgroundColor: "transparent" }}>
            <a
              className="social"
              href="https://medium.com/@pandu_windito"
              target="_blank"
            >
              <FaMedium />
            </a>
          </div>
          <div className="col" style={{ backgroundColor: "transparent" }}>
            <a
              className="social"
              href="https://open.spotify.com/show/1KBPDlVOiKOdOYEaGhhZk0?si=6932e876199a4729"
              target="_blank"
            >
              <FaSpotify />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom mt-5">
        <p>copyright &copy; Pandu Windito </p>
      </div>
    </footer>
  );
};

export default Footer;
