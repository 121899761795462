import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FaCaretDown } from "react-icons/fa";
import "./Navbar.css";
import { useEffect, useState } from "react";
import { FaMoon, FaSun } from "react-icons/fa";

const NavigationBar = () => {
  const [themeDark, setThemeDark] = useState(true);

  const changeTheme = () => {
    setThemeDark(!themeDark);
    if (!themeDark) {
      setTextColor(darkColor);
    } else {
      setTextColor(normalColor);
    }
  };
  // darktheme pallete
  const darkColor = "hsl(207, 90%, 72%)";
  const bgDarkcolor = "hsl(219, 48%, 8%)";

  // normaltheme pallete
  const normalColor = "#fff";

  const [textColor, setTextColor] = useState(darkColor);
  return (
    <>
      <Navbar
        id="nav"
        expand="lg"
        className="shadow p-3"
        style={{
          padding: "20px",
          position: "sticky",
          top: "0",
          zIndex: "1",
          width: "100%",
        }}
      >
        <Container>
          <Navbar.Brand
            href="/"
            style={{ color: textColor, fontWeight: "500" }}
          >
            Pandu Windito
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            style={{
              marginLeft: "25%",
            }}
          >
            <Nav
              className="ml-auto "
              style={{
                alignSelf: "center",
                marginRight: "auto",
                color: textColor,
                fontSize: "0.875rem",
              }}
              // defaultActiveKey="#home"
              // activeKey={""}
            >
              <Nav.Link
                id="nav-home"
                href="#home"
                onClick={() => {
                  document.getElementById("home-container").scrollIntoView();
                }}
                style={{ color: textColor }}
              >
                Home
              </Nav.Link>
              <Nav.Link
                id="nav-about"
                href="#about"
                onClick={() => {
                  document.getElementById("about-container").scrollIntoView();
                }}
                style={{ color: textColor }}
              >
                About
              </Nav.Link>
              <Nav.Link
                id="nav-service"
                href="#service"
                onClick={() => {
                  document.getElementById("service-container").scrollIntoView();
                }}
                style={{ color: textColor }}
              >
                Service
              </Nav.Link>
              <Nav.Link
                id="nav-skill"
                href="#skill"
                onClick={() => {
                  document.getElementById("skill-container").scrollIntoView();
                }}
                style={{ color: textColor }}
              >
                Skill
              </Nav.Link>
              <Nav.Link
                id="nav-work"
                href="#work"
                onClick={() => {
                  document.getElementById("work-container").scrollIntoView();
                }}
                style={{ color: textColor }}
              >
                Work
              </Nav.Link>
              <Nav.Link
                id="nav-contact"
                href="#contact"
                onClick={() => {
                  document.getElementById("contact-container").scrollIntoView();
                }}
                style={{ color: textColor }}
              >
                Contact
              </Nav.Link>
            </Nav>
            <Navbar.Brand
              style={{ color: textColor, cursor: "pointer" }}
              onClick={() => {
                changeTheme();
              }}
            >
              {/* {themeDark ? <FaMoon></FaMoon> : <FaSun></FaSun>} */}
            </Navbar.Brand>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavigationBar;
