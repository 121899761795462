import SkillItem from "../../component/Skill/SkillItem";

const Skill = () => {
  return (
    <>
      <div
        id="skill-container"
        className="container"
        style={{ position: "relative", paddingTop: "5rem" }}
      >
        <p style={{ margin: "0", opacity: "0.5" }}>My Abilities</p>
        <h4>Skills</h4>
        <div
          className="row mt-3 p-5"
          style={{
            backgroundColor: "hsl(219, 32%, 12%)",
            borderRadius: "0.75rem",
            padding: "2rem",
            textAlign: "left",
          }}
        >
          <div className="col" style={{ backgroundColor: "transparent" }}>
            <SkillItem skill="HTML" level="Advanced" />
            <SkillItem skill="CSS" level="Advanced" />
            <SkillItem skill="Javascript" level="Advanced" />
            <SkillItem skill="PHP" level="Advanced" />
            <SkillItem skill="Bootstrap" level="Advanced" />
          </div>
          <div className="col" style={{ backgroundColor: "transparent" }}>
            <SkillItem skill="Vue JS" level="Advanced" />
            <SkillItem skill="React JS" level="Advanced" />
            <SkillItem skill="Laravel" level="Advanced" />
            <SkillItem skill="MySQL" level="Advanced" />
            <SkillItem skill="PostgreSQL" level="Advanced" />
          </div>
          <div className="col" style={{ backgroundColor: "transparent" }}>
            <SkillItem skill="TypeScript" level="Intermediate" />
            <SkillItem skill="Outsystem" level="Intermediate" />
            <SkillItem skill="Python" level="Intermediate" />
            <SkillItem skill="Flask" level="Intermediate" />
            <SkillItem skill="Software AG" level="Intermediate" />
          </div>
          <div className="col" style={{ backgroundColor: "transparent" }}>
            <SkillItem skill="Kotlin" level="Intermediate" />
            <SkillItem skill="Springboot" level="Intermediate" />
            <SkillItem skill="Flutter" level="Intermediate" />
            <SkillItem skill="Java" level="Intermediate" />
            <SkillItem skill="C" level="Intermediate" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Skill;
