import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./page/Home/Home";
import About from "./page/About/About";
import Contact from "./page/Contact/Contact";
import Service from "./page/Service/Service";
import Skill from "./page/Skill/Skill";
import Work from "./page/Work/Work";
import ScrollButton from "./component/ScrollButton";
import NavigationBar from "./component/Navbar/Navbar";
import Footer from "./component/Footer/Footer";
import React, { useState, useEffect } from "react";

function App() {
  useEffect(() => {
    const h = document.getElementById("home-container");
    const a = document.getElementById("about-container");
    const s = document.getElementById("service-container");
    const sk = document.getElementById("skill-container");
    const w = document.getElementById("work-container");
    const c = document.getElementById("contact-container");

    const hn = document.getElementById("nav-home");
    const an = document.getElementById("nav-about");
    const sn = document.getElementById("nav-service");
    const skn = document.getElementById("nav-skill");
    const wn = document.getElementById("nav-work");
    const cn = document.getElementById("nav-contact");
    const sections = [h, a, s, sk, w, c];
    const navs = [hn, an, sn, skn, wn, cn];

    function scrollActive() {
      const scrollY = window.scrollY;
      sections.map((current, i) => {
        // const sectionHeight = current.offsetHeight,
        if (current != null) {
          const sectionHeight = current.offsetHeight;
          const sectionTop = current.offsetTop - 58;
          if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
            navs[i].classList.add("active");
          } else {
            navs[i].classList.remove("active");
          }
        }
      });
    }
    window.addEventListener("scroll", scrollActive);
  });

  return (
    <div className="App">
      <NavigationBar />
      <div className="body">
        <Home />
        <About />
        <Service />
        <Skill />
        <Work />
        <Contact />
        <ScrollButton
          style={{
            left: "auto",
            backgroundColor: "transparent",
          }}
        />
        <Footer />
      </div>
      {/* <Router>
        <Routes>
          <Route path="/" element={}></Route>
        </Routes>
        <Routes>
          <Route path="/test" element={<Test />}></Route>
        </Routes>
      </Router> */}
    </div>
  );
}

export default App;
