import { FaArrowRight, FaTimes, FaCheck } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import "./ServiceItem.css";
import DetailServiceItem from "./DetailServiceItem";

const ServiceItem = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        style={{
          backgroundColor: "hsla(219, 28%, 16%, 0.2)",
          padding: "2rem",
          transition: "0.4s",
          top: "40%",
          bottom: "50%",
          // transform: "translateY(-50%)",
          textAlign: "center",
          width: "100vw",
          height: "100%",
        }}
      >
        <Modal.Body style={{ paddingBottom: "3rem" }}>
          <div style={{ textAlign: "end", paddingBottom: "1rem" }}>
            <FaTimes style={{ cursor: "pointer" }} onClick={handleClose} />
          </div>
          <h3>
            {props.title1} {props.title2}
          </h3>
          <p>What do I do with this service?</p>
          {props.items.map((i) => (
            <DetailServiceItem item={i} />
          ))}
        </Modal.Body>
      </Modal>

      <div className="col">
        <div
          className="col mb-3"
          style={{
            backgroundColor: "hsl(219, 32%, 12%)",
            borderRadius: "0.75rem",
            padding: "2rem",
            textAlign: "left",
            minWidth: "275px",
          }}
        >
          <h3
            style={{
              backgroundColor: "transparent",
              fontSize: "1.25rem",
              color: "hsl(219, 15%, 95%)",
              fontWeight: "600",
              marginTop: "2rem",
              marginBottom: "1rem",
            }}
          >
            {props.title1}
            <br></br>
            {props.title2}
          </h3>
          <p
            style={{
              backgroundColor: "transparent",
              marginBottom: "0",
              fontSize: "0.875rem",
              cursor: "pointer",
            }}
            onClick={() => {
              setShow(true);
            }}
          >
            See more
            <FaArrowRight style={{ backgroundColor: "transparent" }} />
          </p>
        </div>
      </div>
    </>
  );
};
export default ServiceItem;
