import { FaArrowRight } from "react-icons/fa";
import "./ContactItem.css";

const ContactItem = (props) => {
  return (
    <div className="col-4 mt-3" style={{ minWidth: "400px" }}>
      <div
        className="col"
        style={{
          backgroundColor: "hsl(219, 32%, 12%)",
          borderRadius: "0.75rem",
          paddingBottom: "1rem",
        }}
      >
        <h3
          style={{
            backgroundColor: "transparent",
            fontSize: "1.25rem",
            color: "hsl(219, 15%, 95%)",
            fontWeight: "600",
            padding: "1rem 0 0 0",
          }}
        >
          {props.icon}
          <br></br>
          {props.title}
        </h3>
        <p style={{ backgroundColor: "transparent", fontSize: "0.75rem" }}>
          {props.content}
        </p>
        <a
          style={{
            backgroundColor: "transparent",
            paddingBottom: "1rem",
            fontSize: "0.875rem",
            textDecoration: "none",
            color: "hsl(219, 15%, 95%)",
          }}
          href={props.link}
          target="_blank"
        >
          Write Me
          <i className="arrow" style={{ backgroundColor: "transparent" }}>
            <FaArrowRight
              style={{
                backgroundColor: "transparent",
                marginLeft: "5px",
              }}
            />
          </i>
        </a>
      </div>
    </div>
  );
};
export default ContactItem;
