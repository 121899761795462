import { FaRocket } from "react-icons/fa";

const SkillItem = (props) => {
  return (
    <div
      style={{
        display: "flex",
        columnGap: "0.5rem",
        backgroundColor: "transparent",
      }}
    >
      <FaRocket
        style={{
          backgroundColor: "transparent",
          fontWeight: "500",
          fontSize: "1rem",
          color: "hsl( 207 , 90% , 72% )",
        }}
      />
      <div style={{ backgroundColor: "transparent" }}>
        <p
          style={{
            color: "hsl( 219 , 15%, 95%)",
            fontSize: "1rem",
            fontWeight: "500",
            lineHeight: "18px",
            marginBottom: "3px",
            backgroundColor: "transparent",
          }}
        >
          {props.skill}
        </p>
        <p
          style={{
            color: "hsl( 219 , 15%, 95%)",
            fontSize: "0.625rem",
            opacity: "0.7",
            backgroundColor: "transparent",
          }}
        >
          {props.level}
        </p>
      </div>
    </div>
  );
};
export default SkillItem;
