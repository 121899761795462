import Tilt from "react-parallax-tilt";
import { FaTrophy, FaAward } from "react-icons/fa";
import { Button } from "react-bootstrap";

const About = () => {
  return (
    <>
      <div
        id="about-container"
        className="container"
        style={{ position: "relative", paddingTop: "5rem" }}
      >
        <p style={{ margin: "0", opacity: "0.5" }}>Introduction</p>
        <h4>About Me</h4>
        <div className="row mt-5">
          <div className="col-12 col-md-6 col-lg-6 mb-5 d-flex align-items-center p-3">
            <div style={{ width: "100%", alignItems: "center" }}>
              <Tilt
                style={{
                  maxWidth: "500px",
                  width: "100%",
                  height: "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  borderRadius: "50%",
                  clipPath: "circle()",
                }}
              >
                <img
                  src="/photo.jpg"
                  style={{
                    maxWidth: "500px",
                    width: "100%",
                    height: "100%",
                    marginLeft: "10px",
                  }}
                ></img>
              </Tilt>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 align-items-center p-5">
            <div
              className="row"
              style={{ alignItems: "center", gap: "0.5rem" }}
            >
              <div
                className="col"
                style={{
                  backgroundColor: "hsl(219, 32%, 12%)",
                  borderRadius: "0.75rem",
                  padding: "1rem 1.25rem",
                }}
              >
                <FaTrophy
                  style={{ backgroundColor: "transparent", fontSize: "1.5rem" }}
                ></FaTrophy>
                <p
                  style={{
                    backgroundColor: "transparent",
                    fontSize: "0.875rem",
                    color: "hsl(219, 15%, 95%)",
                  }}
                >
                  Experience
                </p>
                <p
                  style={{
                    backgroundColor: "transparent",
                    marginBottom: "0",
                    fontSize: "0.625rem",
                    color: "hsl(219, 15%, 95%)",
                    opacity: "0.7",
                  }}
                >
                  2 Year Working
                </p>
              </div>
              <div
                className="col"
                style={{
                  backgroundColor: "hsl(219, 32%, 12%)",
                  borderRadius: "0.75rem",
                  padding: "1rem 1.25rem",
                }}
              >
                <FaAward
                  style={{ backgroundColor: "transparent", fontSize: "1.5rem" }}
                ></FaAward>
                <p
                  style={{
                    backgroundColor: "transparent",
                    fontSize: "0.875rem",
                    color: "hsl(219, 15%, 95%)",
                  }}
                >
                  Completed
                </p>
                <p
                  style={{
                    backgroundColor: "transparent",
                    marginBottom: "0",
                    fontSize: "0.625rem",
                    color: "hsl(219, 15%, 95%)",
                    opacity: "0.7",
                  }}
                >
                  5 + project
                </p>
              </div>
            </div>
            <div
              className="row mt-5"
              style={{ width: "100%", textAlign: "justify" }}
            >
              Experienced in developing websites and Android apps since 2020. I
              create websites and mobile apps with UI/UX design, convert design
              into code, and make it ready to use. ready to use.
              <Button
                className="mt-3"
                style={{
                  width: "25%",
                  minWidth: "141px",
                  height: "100%",
                  backgroundColor: "hsl(207, 90%, 72%)",
                  border: "2px solid hsl(207, 90%, 72%)",
                  color: "#0b111e",
                }}
                onClick={() => {
                  document.getElementById("contact-container").scrollIntoView();
                }}
              >
                Contact Me
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
