import { Button } from "react-bootstrap";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import "./Home.css";
import Tilt from "react-parallax-tilt";

const Home = () => {
  return (
    <div
      id="home-container"
      className="container mt-5"
      style={{
        position: "relative",
        paddingTop: "5rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
      }}
    >
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 mb-5 d-flex align-items-center p-3">
          <div
            style={{
              visibility: "visible",
              opacity: "1",
              textAlign: "start",
              width: "100%",
              transform:
                "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
              transition:
                "opacity 2.5s cubic-bezier(0.5, 0, 0, 1) 0.3s, transform 2.5s cubic-bezier(0.5, 0, 0, 1) 0.3s",
            }}
          >
            <span
              style={{
                display: "block",
                color: "white",
                marginBottom: "0.25rem",
              }}
            >
              Hi, I'm
            </span>
            <h2 style={{ fontSize: "2rem", color: "white" }}>Pandu Windito</h2>
            <h4
              style={{
                marginBottom: "2.5rem",
                fontSize: "1rem",
                opacity: "0.8",
                color: "white",
                fontWeight: "300",
              }}
            >
              Fullstack Developer
            </h4>
            <div className="row" style={{ height: "3rem", minWidth: "660px" }}>
              <div className="col-3">
                <a
                  href="https://pdf.veintisietemgm.com/Paulus_Pandu_Windito.pdf"
                  target="_blank"
                >
                  <Button
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "transparent",
                      border: "2px solid hsl(207, 90%, 72%)",
                      color: "hsl(207, 90%, 72%)",
                    }}
                  >
                    Download CV
                  </Button>
                </a>
              </div>
              <div className="col-3">
                <Button
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "hsl(207, 90%, 72%)",
                    border: "2px solid hsl(207, 90%, 72%)",
                    color: "#0b111e",
                  }}
                  onClick={() => {
                    document
                      .getElementById("contact-container")
                      .scrollIntoView();
                  }}
                >
                  Contact Me
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-6 d-flex">
          <div>
            <Tilt>
              <img
                src="/computer.png"
                style={{
                  maxWidth: "400px",
                  width: "100%",
                }}
              ></img>
            </Tilt>
          </div>
        </div>
      </div>
      <div
        className="social-container"
        style={{
          visibility: "visible",
          opacity: "1",
          transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
          transition:
            "opacity 2.5s cubic-bezier(0.5, 0, 0, 1) 0.9s, transform 2.5s cubic-bezier(0.5, 0, 0, 1) 0.9s",
          position: "absolute",
          display: "grid",
          rowGap: "0.5rem",
          right: "0.25rem",
          top: "7rem",
        }}
      >
        <a
          className="social"
          href="https://github.com/just4fun147"
          target="_blank"
        >
          <FaGithub></FaGithub>
        </a>
        <a
          className="social"
          href="https://www.linkedin.com/in/panduwindito/"
          target="_blank"
        >
          <FaLinkedin></FaLinkedin>
        </a>
      </div>
    </div>
  );
};

export default Home;
