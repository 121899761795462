import { Button } from "react-bootstrap";
import WorkItem from "../../component/Work/WorkItem";
import "./Work.css";
import { useState } from "react";
const Work = () => {
  const [filter, setFilter] = useState("all");
  const [more, setMore] = useState(false);
  return (
    <>
      <div
        id="work-container"
        className="container"
        style={{ position: "relative", paddingTop: "5rem" }}
      >
        <p style={{ margin: "0", opacity: "0.5" }}>My Portofolio</p>
        <h4>Recent Works</h4>
        <div className="row mt-5">
          <div
            className="row"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              justifyContent: "center",
              width: "25%",
              minWidth: "330px",
              // gap: "0.25rem",
              padding: "0.25rem",
            }}
          >
            {/* style={{
            backgroundColor: "hsl(207, 90%, 72%)",
            color: "hsl(219, 48%, 8%)",
          }} */}
            <div
              id="all"
              className="col-3 active"
              style={{
                cursor: "pointer",
                padding: "0.25rem 0.5rem ",
                width: "auto",
              }}
              onClick={() => {
                document.getElementById("all").classList.add("active");
                document.getElementById("web").classList.remove("active");
                document.getElementById("mobile").classList.remove("active");
                setFilter("all");
              }}
            >
              All
            </div>
            <div
              id="web"
              style={{
                cursor: "pointer",
                padding: "0.25rem 0.5rem ",
                width: "auto",
              }}
              className="col-3 "
              onClick={() => {
                document.getElementById("all").classList.remove("active");
                document.getElementById("web").classList.add("active");
                document.getElementById("mobile").classList.remove("active");
                setFilter("web");
              }}
            >
              Web
            </div>
            <div
              id="mobile"
              style={{
                cursor: "pointer",
                padding: "0.25rem 0.5rem ",
                width: "auto",
              }}
              className="col-3 "
              onClick={() => {
                document.getElementById("all").classList.remove("active");
                document.getElementById("web").classList.remove("active");
                document.getElementById("mobile").classList.add("active");
                setFilter("mobile");
              }}
            >
              Mobile
            </div>
          </div>
          <div
            className="row row-cols-3 mt-4"
            style={{ padding: "0 3rem 3rem 3rem" }}
          >
            {filter === "all" ? (
              <>
                <WorkItem name="Community Web" image="/veinti.png" />
                <WorkItem name="Election App" image="/election.png" />
                <WorkItem name="E-Commerce" image="/ecom.png" />
                <WorkItem name="Library Website" image="/perpustakaan.png" />
                <WorkItem
                  name="School Website Management"
                  image="/school.png"
                />
                <WorkItem name="Personal Website" image="/personal.png" />
                {more && filter === "all" ? (
                  <>
                    <WorkItem name="Atma Cinema" image="/cinema.jpg" />
                    <WorkItem name="Youtube Clone" image="/youtube.jpg" />
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                {filter === "web" ? (
                  <>
                    <WorkItem name="Community Web" image="/veinti.png" />
                    <WorkItem name="Election App" image="/election.png" />
                    <WorkItem name="E-Commerce" image="/ecom.png" />
                    <WorkItem
                      name="Library Website"
                      image="/perpustakaan.png"
                    />
                    <WorkItem
                      name="School Website Management"
                      image="/school.png"
                    />
                    <WorkItem name="Personal Website" image="/personal.png" />
                  </>
                ) : (
                  <>
                    <WorkItem name="Atma Cinema" image="/cinema.jpg" />
                    <WorkItem name="Youtube Clone" image="/youtube.jpg" />
                  </>
                )}
              </>
            )}
          </div>
          {filter === "all" ? (
            <>
              {!more ? (
                <div
                  className="row"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    style={{
                      width: "auto",
                      backgroundColor: "hsl(207, 90%, 72%)",
                      color: "hsl(219, 32%, 12%)",
                      fontWeight: "500",
                    }}
                    onClick={() => {
                      setMore(true);
                    }}
                  >
                    Show More
                  </Button>
                </div>
              ) : (
                <div
                  className="row"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    style={{
                      width: "auto",
                      backgroundColor: "hsl(207, 90%, 72%)",
                      color: "hsl(219, 32%, 12%)",
                      fontWeight: "500",
                    }}
                    onClick={() => {
                      setMore(false);
                    }}
                  >
                    Show Less
                  </Button>
                </div>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default Work;
