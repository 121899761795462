import { FaTelegramPlane } from "react-icons/fa";
import { BxEnvelopeShare } from "@bx-design/react-icons";
import ContactItem from "../../component/Contact/ContactItem";

const Contact = () => {
  return (
    <>
      <div
        id="contact-container"
        className="container"
        style={{ position: "relative", paddingTop: "5rem" }}
      >
        <p style={{ margin: "0", opacity: "0.5" }}>Get in touch</p>
        <h4>Contact Me</h4>
        <div className="row mt-3 p-5 justify-content-center">
          <ContactItem
            title="Email"
            content="pauluswindito1@gmail.com"
            link="mailto:pauluswindito1@gmail.com"
            icon={
              <BxEnvelopeShare
                style={{
                  backgroundColor: "transparent",
                  fontSize: "2rem",
                }}
              />
            }
          />
          <ContactItem
            title="Telegram"
            content="@panduwindito"
            link="https://telegram.me/panduwindito"
            icon={
              <FaTelegramPlane
                style={{
                  backgroundColor: "transparent",
                  fontSize: "2rem",
                }}
              />
            }
          />
        </div>
      </div>
    </>
  );
};

export default Contact;
