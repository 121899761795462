import { FaCheck } from "react-icons/fa";

const DetailServiceItem = (props) => {
  return (
    <div
      className="col-6"
      style={{
        textAlign: "start",
        marginLeft: "10%",
        marginBottom: "1rem",
      }}
    >
      <FaCheck style={{ marginRight: "1rem" }} />
      {props.item}
    </div>
  );
};
export default DetailServiceItem;
