import ServiceItem from "../../component/Service/ServiceItem";
const Service = () => {
  return (
    <>
      <div
        id="service-container"
        className="container"
        style={{ position: "relative", paddingTop: "5rem" }}
      >
        <p style={{ margin: "0", opacity: "0.5" }}>My Service</p>
        <h4>What I Offer</h4>
        <div className="row mt-3 p-5">
          <ServiceItem
            title1="UI/UX"
            title2="Design"
            items={[
              "User Research.",
              "Visual Design.",
              "Prototyping.",
              "Mockup.",
            ]}
          />
          <ServiceItem
            title1="Web"
            title2="Development"
            items={["Backend.", "Frontend.", "Fullstack"]}
          />
          <ServiceItem
            title1="Mobile"
            title2="Development"
            items={["Android.", "iOS."]}
          />
        </div>
      </div>
    </>
  );
};

export default Service;
