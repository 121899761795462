import { FaArrowRight } from "react-icons/fa";

const WorkItem = (props) => {
  return (
    <div
      className="col"
      style={{
        padding: "0 1rem 1rem 1rem",
        minWidth: "325px",
      }}
    >
      <div
        style={{
          backgroundColor: "hsl(219, 32%, 12%)",
          borderRadius: "0.75rem",
          textAlign: "left",
          padding: "1rem",
        }}
      >
        <div
          className="row"
          style={{
            width: "100%",
            backgroundColor: "transparent",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div
            style={{
              height: "150px",
              borderRadius: "0.25rem",
              padding: "0",
            }}
          >
            <img
              src={props.image}
              style={{
                objectFit: "cover",
                width: "100%",
                overflow: "hidden",
                borderRadius: "0.25rem",
                height: "150px",

                // height: "100%",
              }}
            ></img>
          </div>
        </div>
        <div
          className="row mt-2"
          style={{
            backgroundColor: "transparent",
          }}
        >
          <p style={{ backgroundColor: "transparent" }}>{props.name}</p>
        </div>
      </div>
    </div>
  );
};

export default WorkItem;
